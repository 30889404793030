import { Box, Typography, styled } from '@mui/material'
import React from 'react'
import AboutUsImg from '../pics/AboutUsImg.jpg'
import OurServices from '../pics/OurServices.jpg'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

//images
import GedImg1 from '../pics/gallery/gedimg1.jpg'
import GedImg2 from '../pics/gallery/gedimg2.jpg'
import GedImg3 from '../pics/gallery/gedimg3.jpg'
import GedImg4 from '../pics/gallery/gedimg4.jpg'
import GedImg5 from '../pics/gallery/gedimg5.jpg'
import GedImg6 from '../pics/gallery/gedimg6.jpg'

const AboutUs = () => {
  const CustomTypo=styled(Typography)(({theme})=>({
    fontSize:'30px',
    [theme.breakpoints.down('md')]:{
      fontSize:'20px'
  }
  }))
  const AboutContainer=styled(Box)(({theme})=>({
    display:'flex',
    justifyContent:'space-around',
    width:'100%',
    height:'100%',
    padding:'20px',
    backgroundColor:'#fff',
    [theme.breakpoints.down('md')]:{
      flexDirection:'column',
      alignItems:'center'
  }
  }))
  const AboutContent=styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    maxWidth:600,
    padding:'30px'
  }))
  const AboutTitle=styled(Typography)(({theme})=>({
    fontFamily:'Satisfy',
    lineHeight:1.5,
    fontSize:'62px',
    marginBottom:'20px',
    [theme.breakpoints.down('sm')]:{
      fontSize:'32px'
  }
  }))
  const AboutDescription=styled(Typography)(({theme})=>({
    fontFamily:'',
    lineHeight:1.25,
    letterSpacing:1.25,
    marginBottom:'3em',
    fontSize:'20px',
    [theme.breakpoints.down('md')]:{
      lineHeight:1.15,
      letterSpacing:1.15,
      marginBottom:'1.5em',
      fontSize:'15px'
  }
  }))
  const AboutImage=styled('img')(({theme})=>({
    width:'400px',
    
    [theme.breakpoints.down('md')]:{
      width:'350px'
    },
    [theme.breakpoints.down('sm')]:{
      width:'250px',
      paddingBottom:'20px'
    }
  }))
  const OurServicesImage=styled('img')(({theme})=>({
    width:'600px',
    
    [theme.breakpoints.down('md')]:{
      width:'350px'
    },
    [theme.breakpoints.down('sm')]:{
      width:'250px',
      paddingBottom:'20px'
    }
  }))
  const CustomLi=styled('li')(({theme})=>({
    fontSize:'15px',
    padding:'5px',
    [theme.breakpoints.down('sm')]:{
      fontSize:'12px'
    }
  }))
  const GalleryBox=styled(Box)(({theme})=>({
    textAlign:'center',
    padding:'0px',
    backgroundColor:'#fff',
    borderRadius:'0px 0px 20px 20px'
  }))
  const GalleryImg=styled('img')(({theme})=>({
    width:'1000px',
    height:'auto',
    [theme.breakpoints.down('md')]:{
      width:'100%'
    }
  }))
  return (
    <div id='aboutus'>
      <Box sx={{backgroundColor:'#000000',padding:'30px'}}>
        <Box sx={{textAlign:'center',paddingBottom:'30px'}}>
          <CustomTypo><span className='text-span'>About Us</span></CustomTypo>
        </Box>     
        <AboutContainer sx={{borderRadius:'20px 20px 0px 0px'}}>
          <AboutContent>
            <AboutTitle>What We Do</AboutTitle>
            <AboutDescription>
              Welcome to Global Events and Decors! We transform ordinary spaces into extraordinary settings with creativity, precision, and flair. From intimate gatherings to grand celebrations and corporate events, our dedicated team is committed to bringing your vision to life.
            </AboutDescription>
          </AboutContent>
          <AboutImage src={AboutUsImg}/>
        </AboutContainer>  
        <AboutContainer sx={{borderRadius:'0px 0px 0px 0px'}}>
          <OurServicesImage src={OurServices}/>
          <AboutContent>
            <AboutTitle>Our Services</AboutTitle>
            <AboutDescription>
              At Global Events and Decors, we offer a comprehensive range of services to cater to all your event needs:
                <CustomLi sx={{paddingTop:'20px'}}>Event Planning and Coordination</CustomLi>
                <CustomLi>Decoration and Design</CustomLi>
                <CustomLi>Floral Arrangements</CustomLi>
                <CustomLi>Lighting and Audio-Visual</CustomLi>
                <CustomLi>Catering and Hospitality</CustomLi>
                <CustomLi>Entertainment and Performances</CustomLi>
                <CustomLi>Photography and Videography</CustomLi>
            </AboutDescription>
          </AboutContent>
        </AboutContainer>  
        <GalleryBox id='gallery'>
        <Carousel data-bs-theme="dark" fade pause='false'>
            <Carousel.Item interval={2500}>
            <GalleryImg src={GedImg1} alt="" srcset="" />
            </Carousel.Item>
            <Carousel.Item interval={2500}>
            <GalleryImg src={GedImg2} alt="" srcset="" />
            </Carousel.Item>
            <Carousel.Item interval={2500}>
            <GalleryImg src={GedImg3} alt="" srcset="" />
            </Carousel.Item>
            <Carousel.Item interval={2500}>
            <GalleryImg src={GedImg4} alt="" srcset="" />
            </Carousel.Item>
            <Carousel.Item interval={2500}>
            <GalleryImg src={GedImg5} alt="" srcset="" />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
            <GalleryImg src={GedImg6} alt="" srcset="" />
            </Carousel.Item>
      </Carousel>
        </GalleryBox> 
      </Box>
    </div>
  )
}

export default AboutUs
