import { Alert, Box, Grid, styled, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

const Book = () => {
    const CustomTypo=styled(Typography)(({theme})=>({
        fontSize:'30px',
        [theme.breakpoints.down('md')]:{
          fontSize:'20px'
    }
    }))
    const ContactDescription=styled(Typography)(({theme})=>({
        color:'#fff',
        lineHeight:1.25,
        letterSpacing:1.25,
        fontSize:'15px',
        [theme.breakpoints.down('md')]:{
          lineHeight:1.15,
          letterSpacing:1.15,
          fontSize:'10px'
      }
    }))
    const CustomFormBox=styled(Box)(({theme})=>({
        backgroundColor:'#e0e0e0',
        minWidth:'50vw',
        padding:'10px',
        borderRadius:'10px',
        [theme.breakpoints.down('sm')]:{
            minWidth:'80vw'
        }
    }))
    const CustomTextField=styled(TextField)(({theme})=>({
        paddingBottom:'10px',
        [theme.breakpoints.down('sm')]:{
      }
    }))
    const CustomButton=styled('input')(({theme})=>({
        color:'#fff',
        backgroundColor:'#000000',
        fontWeight:'bold',
        paddingLeft:'20px',
        paddingRight:'20px',
        borderRadius:'5px',
        '&:hover':{
            color:'#fdd835'
        }
    }))


    const [data, setData] = useState(null); 
    const form = useRef();

    const sendEmail = (e) => {
       
       e.preventDefault();

    emailjs.sendForm('service_n5shiyd', 'template_4kdasfe', form.current, {publicKey: 'Ggxe3Y1BksZ1_DJWe'})
      .then(
        () => {
          console.log('SUCCESS!');
          e.target.reset();
        //   alert('Message Send.');
        setData(<Alert severity="success">Message send successfully.</Alert>)
        setTimeout(() => {
            setData();
        },3000);        
        },
        (error) => {
          console.log('FAILED...', error.text);
        //   alert('Error sending message.')
        setData(<Alert severity="error">Error sending message.</Alert>)
        setTimeout(() => {
            setData();
        },3000);
        },
    );
    };
    return (
    <div id='booknow'>
        <Box sx={{backgroundColor:'#000000',padding:'20px'}}>
            <Box sx={{textAlign:'center',paddingBottom:'40px'}}>
                <CustomTypo><span className='text-span'>Book Now</span></CustomTypo>
                <ContactDescription>
                    Please Complete the form below.
                    For a faster response, feel free to call us directly.
                </ContactDescription>
            </Box>  
            <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CustomFormBox>
                    <form ref={form} onSubmit={sendEmail}>
                        <Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <CustomTextField variant='filled' label="Name" name='user_name' autoComplete='off' required fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <CustomTextField variant='filled' label="Email" name='user_email' autoComplete='off' required fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <CustomTextField variant='filled' label="Phone" name='user_phone' type='number' autoComplete='off' required fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <CustomTextField variant='filled' label="Place" name='user_place' autoComplete='off' required fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{paddingBottom:'10px'}}>
                                <label style={{paddingLeft:'12px'}}>Event *</label>
                                <select name="user_event" id="event-select" required>
                                    <option value="">--Choose an option--</option>
                                    <option value="Wedding">Wedding</option>
                                    <option value="Birthday">Birthday</option>
                                    <option value="Anniversary">Anniversary</option>
                                    <option value="Baby Shower/ Cradle Ceremony">Baby Shower/Cradle Ceremony</option>
                                    <option value="Corporate Event">Corporate Event</option>
                                    <option value="Rituals and Ceremonies">Rituals and Ceremonies</option>
                                    <option value="Other">Other</option>
                                </select>
                            </Grid>                            
                            <Grid item xs={12} sm={12} md={12}>
                                <CustomTextField variant='filled' rows={4} multiline label="Message" name='message' autoComplete='off' fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{textAlign:'center'}}>
                                <CustomButton type="submit" value="Submit" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box>
                                    <span>{data}</span>
                                </Box>
                            </Grid>    
                        </Grid>        
                    </form>
                    
                    
                </CustomFormBox>
            </Box>
        </Box>
    </div>
    )
}

export default Book
