import { AppBar, Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CollectionsIcon from '@mui/icons-material/Collections';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import NavLogo from '../pics/GED-LOGO-NoText.png'
import { Link } from 'react-scroll';

const Header = () => {
    const [mobileMenu,setMobileMenu]=useState({right:false});    

    const NavBarName=styled(Typography)(({theme})=>({
        fontFamily:'Grandiflora One',
        fontWeight:600,
        paddingBottom:'10px',
        fontSize:'30px',
        color:'#fdd835',
        cursor:'pointer',
        '&:hover':{
            color:'#fff'
        },
        [theme.breakpoints.down('md')]:{
            fontSize:'15px',
            fontWeight:600
        }
    }))
    const NavBarLogo=styled("img")(()=>({
        cursor:'pointer',
        width:'50px',
        height:'50px',
    }))
    const NavBarLinksBox=styled(Box)(({theme})=>({
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:theme.spacing(3),
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    }))
    const NavBarLinkText=styled(Typography)(()=>({
        fontSize:'15px',
        color:'#fff',
        fontFamily:'Signika Negative',
        fontWeight:'bold',
        cursor:'pointer',
        '&:hover':{
            color:'#fdd835',
        }
    }))
    const CustomMenuIcon=styled(MenuIcon)(({theme})=>({
        cursor:'pointer',
        display:'none',
        color:'#fff',
        marginBottom:'10px',
        marginRight:theme.spacing(2),
        [theme.breakpoints.down('md')]:{
            display:'block'
        }
    }))
    const toggleDrawer=(anchor,open)=>(event)=>{
        if(event.type==='keydown'&& (event.key=== 'Tab' || event.key === 'Shift')){
            return;
        }
        setMobileMenu({...mobileMenu, [anchor]: open })
    };
    
    const list=(anchor)=>(
        <Box 
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        >
        <List>
            <ListItem>
                <Link to="home" spy={true} smooth={true} offset={-100} duration={500} onClick={toggleDrawer(anchor, false)}>
                    <ListItemButton>
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText>HOME</ListItemText>
                    </ListItemButton>
                </Link>
            </ListItem>
            <ListItem>
                <Link to="aboutus" spy={true} smooth={true} offset={-90} duration={500} onClick={toggleDrawer(anchor, false)}>
                    <ListItemButton>
                        <ListItemIcon><InfoIcon/></ListItemIcon>
                        <ListItemText>ABOUT US</ListItemText>
                    </ListItemButton>
                </Link>
            </ListItem>
            <ListItem>
                <Link to="home" spy={true} smooth={true} offset={-100} duration={500} onClick={toggleDrawer(anchor, false)}>
                    <ListItemButton>
                        <ListItemIcon><CollectionsIcon/></ListItemIcon>
                        <ListItemText>GALLERY</ListItemText>
                    </ListItemButton>
                </Link>
            </ListItem>
            <ListItem>
                <Link to="contactus" spy={true} smooth={true} offset={-90} duration={500} onClick={toggleDrawer(anchor, false)}>
                    <ListItemButton>
                        <ListItemIcon><ContactMailIcon/></ListItemIcon>
                        <ListItemText>CONTACT US</ListItemText>
                    </ListItemButton>
                </Link>
            </ListItem>
            <ListItem>
                <Link to="booknow" spy={true} smooth={true} offset={-90} duration={500} onClick={toggleDrawer(anchor, false)}>
                    <ListItemButton>
                        <ListItemIcon><AppRegistrationIcon/></ListItemIcon>
                        <ListItemText>BOOK NOW</ListItemText>
                    </ListItemButton>
                </Link>
            </ListItem>
        </List>
        </Box>
    )
    return (
        <div>
        <AppBar sx={{backgroundColor:'#000000',minHeight:'auto',position:"fixed-top"}}>
            <Box sx={{display:'flex',justifyContent:'space-between',padding:'20px'}}>
                <Box sx={{display:'flex',alignItems:'center',gap:'1rem',textAlign:'center'}}>
                    <Link to="home" spy={false} smooth={true} offset={-100} duration={500}>
                        <NavBarLogo src={NavLogo}/>
                    </Link>
                    <Link to="home" spy={false} smooth={true} offset={-100} duration={500}>
                        <NavBarName>GLOBAL EVENTS & DECORS</NavBarName>
                    </Link>
                </Box>
                <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',gap:'2.5rem'}}>
                    <NavBarLinksBox> 
                        <Link to="home" spy={false} smooth={true} offset={-100} duration={500}>                       
                        <NavBarLinkText variant='body2'>HOME</NavBarLinkText>
                        </Link>
                        <Link to="aboutus" spy={false} smooth={true} offset={-89} duration={500}>
                        <NavBarLinkText variant='body2'>ABOUT US</NavBarLinkText>
                        </Link>
                        <Link to="gallery" spy={false} smooth={true} offset={-100} duration={500}>
                        <NavBarLinkText variant='body2'>GALLERY</NavBarLinkText>
                        </Link>
                        <Link to="contactus" spy={false} smooth={true} offset={-80} duration={500}>
                        <NavBarLinkText variant='body2'>CONTACT US</NavBarLinkText>
                        </Link>
                        <Link to="booknow" spy={false} smooth={true} offset={-90} duration={500}>
                        <NavBarLinkText variant='body2'>BOOK NOW</NavBarLinkText>
                        </Link>
                    </NavBarLinksBox>  
                    <CustomMenuIcon onClick={toggleDrawer("right",true)}/>
                    <Drawer anchor='right' open={mobileMenu["right"]} onClose={toggleDrawer("right",false)}>
                        {list("right")}
                    </Drawer>  
                </Box>
            </Box>
        </AppBar>
        </div>
    )
}

export default Header
