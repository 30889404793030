import { Box, Container, styled, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-scroll';
import WhatsAppIcon from '../pics/WhatsAppIcon.png'
import YoutubeIcon from '../pics/YoutubeIcon.png'
import InstagramIcon from '../pics/InstagramIcon.png'

const Footer = () => {
    const CustomContainer=styled(Container)(({theme})=>({
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(5),
        [theme.breakpoints.down("sm")]:{
            flexDirection:'column',
            textAlign:'center'
        }
    }));
    const NavBarLinkText=styled(Typography)(({theme})=>({
        display:'flex',
        fontSize:'15px',
        color:'#AAABB8',
        fontFamily:'Signika Negative',
        cursor:'pointer',
        '&:hover':{
            color:'#fdd835',
            fontWeight:'bold'
        },
        [theme.breakpoints.down("sm")]:{
            justifyContent:'center',
            fontSize:'10px'
        }
    
    }))
    const IconBox=styled(Box)(({theme})=>({
        display: 'flex',
        alignItems:'center',
        gap: '1rem',
        padding:'10px',
        [theme.breakpoints.down("sm")]:{
            justifyContent:'center'
        }
    }));
    const CustomFooterTypo=styled(Typography)(({theme})=>({
        color:'#fff',
        fontSize:'20px',
        fontWeight:'700',
        mb:2,
        paddingBottom:'5px',
        [theme.breakpoints.down("sm")]:{
            fontSize:'15px'
        }
    }))
    const CustomFooterDescTypo=styled(Typography)(({theme})=>({
        color:'#AAABB8',
        fontSize:'16px',
        fontWeight:'500',
        mb:2,
        [theme.breakpoints.down("sm")]:{
            fontSize:'11px'
        }
    }))
    const CustomTypo=styled(Typography)(({theme})=>({
        color:'#AAABB8',
        fontSize:'15px',
        [theme.breakpoints.down("md")]:{
            fontSize:'10px'
        }
    }))
  return (
    <div>
      <Box sx={{backgroundColor:'#212121',padding:'20px'}}>
        <CustomContainer>
            <Box>
                <CustomFooterTypo>Quick Links</CustomFooterTypo>
                <Link to="home" spy={true} smooth={true} offset={-100} duration={500}>                       
                    <NavBarLinkText variant='body2'>HOME</NavBarLinkText>
                </Link>
                <Link to="aboutus" spy={true} smooth={true} offset={-89} duration={500}>
                    <NavBarLinkText variant='body2'>ABOUT US</NavBarLinkText>
                </Link>
                <Link to="home" spy={true} smooth={true} offset={-100} duration={500}>
                    <NavBarLinkText variant='body2'>GALLERY</NavBarLinkText>
                </Link>
                <Link to="contactus" spy={true} smooth={true} offset={-90} duration={500}>
                    <NavBarLinkText variant='body2'>CONTACT US</NavBarLinkText>
                </Link>
                <Link to="booknow" spy={true} smooth={true} offset={-90} duration={500}>
                    <NavBarLinkText variant='body2'>BOOK NOW</NavBarLinkText>
                </Link>
            </Box>
            <Box>
                <CustomFooterTypo>Get in touch</CustomFooterTypo>
                <CustomFooterDescTypo>Keep in touch with our social medias.</CustomFooterDescTypo>
                <IconBox>
                    <Tooltip title='WhatsApp'>
                        <a href="https://wa.me/+917012159903"><img src={WhatsAppIcon} alt='WhatsAppIcon' style={{cursor:'pointer',width:'30px'}}/></a>
                    </Tooltip>
                    <Tooltip title='Youtube'>
                        <a href="https://www.youtube.com/@globaleventsanddecors"><img src={YoutubeIcon} alt='YoutubeIcon' style={{cursor:'pointer',width:'30px'}}/></a>
                    </Tooltip>
                    <Tooltip title='Instagram'>
                        <a href="https://www.instagram.com/globaleventsanddecors/"><img src={InstagramIcon} alt='InstagramIcon' style={{cursor:'pointer',width:'30px'}}/></a>
                    </Tooltip>
                </IconBox>
            </Box>
        </CustomContainer>
        
      </Box>
      <Box sx={{backgroundColor:'#212121',textAlign:'center',justifyContent:'center'}}>
        <CustomTypo>Copyright &copy; 2024 GLOBAL EVENTS & DECORS.All rights reserved.</CustomTypo>
      </Box>
    </div>
  )
}

export default Footer
