import { Box, styled, Tooltip, Typography } from '@mui/material'
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';


const ContactUs = () => {
    const CustomTypo=styled(Typography)(({theme})=>({
        fontSize:'30px',
        [theme.breakpoints.down('md')]:{
          fontSize:'20px'
    }
    }))
    const CustomContainer=styled(Box)(({theme})=>({
        display:'flex',
        justifyContent:'space-around',
        width:'100%',
        height:'100%',
        padding:'20px',
        backgroundColor:'#fff',
        [theme.breakpoints.down('md')]:{
          flexDirection:'column',
          alignItems:'center'
      }
      }))
      const CustomContent=styled(Box)(({theme})=>({
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        maxWidth:600,
        padding:'30px'
      }))
      const CustomTitle=styled(Typography)(({theme})=>({
        fontFamily:'Satisfy',
        lineHeight:1.5,
        fontSize:'32px',
        [theme.breakpoints.down('sm')]:{
          fontSize:'22px'
      }
      }))
      const ContactDescription=styled(Typography)(({theme})=>({
        color:'#fff',
        lineHeight:1.25,
        letterSpacing:1.25,
        fontSize:'15px',
        [theme.breakpoints.down('md')]:{
          lineHeight:1.15,
          letterSpacing:1.15,
          fontSize:'10px'
      }
      }))
      const CustomDescription=styled(Typography)(({theme})=>({
        fontFamily:'',
        lineHeight:1.25,
        letterSpacing:1.25,
        marginBottom:'1em',
        fontSize:'15px',
        [theme.breakpoints.down('md')]:{
            lineHeight:1.15,
            letterSpacing:1.15,
            marginBottom:'1.5em'
        }
      }))
      const CustomMap=styled('iframe')(({theme})=>({
        width:'800px',
        height:'500px',
        border:0,        
        [theme.breakpoints.down('md')]:{
          width:'350px',
          height:'300px',
        },
        [theme.breakpoints.down('sm')]:{
          width:'250px',
          paddingBottom:'20px'
        }
      }))
    return (
    <div id='contactus'>
        <Box sx={{backgroundColor:'#000000',padding:'20px'}}>
            <Box sx={{textAlign:'center',paddingBottom:'25px'}}>
                <CustomTypo><span className='text-span'>Contact Us</span></CustomTypo>
                <ContactDescription>
                    Ready to start planning your next event? Contact us today to discuss your vision and let us help you create an event that will be remembered for years to come.
                </ContactDescription>
            </Box>  
            <CustomContainer sx={{borderRadius:'20px'}}>
                <CustomContent>
                    <CustomTitle>Our Address:</CustomTitle>
                    <CustomDescription>
                      NORTH PARAVUR <br />
                      ERNAKULAM <br />
                      KERALA
                    </CustomDescription>
                    <CustomTitle>Phone:</CustomTitle>
                    <CustomDescription>
                      +91 7012159903
                    </CustomDescription>
                    <CustomTitle>Email:</CustomTitle>
                    <CustomDescription>
                      globaleventsanddecors@gmail.com
                    </CustomDescription>
                    <CustomTitle>Social Medias:</CustomTitle>
                    <CustomDescription>
                        <Tooltip title='WhatsApp'><a href="https://wa.me/+917012159903" style={{color:'inherit'}}><WhatsAppIcon/></a></Tooltip>
                        <Tooltip title='Instagram'><a href="https://www.instagram.com/globaleventsanddecors/" style={{color:'inherit'}}><InstagramIcon/></a></Tooltip>
                        <Tooltip title='YouTube'><a href="https://www.youtube.com/" style={{color:'inherit'}}><YouTubeIcon/></a></Tooltip>                      
                    </CustomDescription>
                </CustomContent>
                <CustomMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15709.860388488256!2d76.219766340899!3d10.142797412005285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0810825b734d09%3A0x7fabaa540f2b9415!2sNorth%20Paravur%2C%20Kerala!5e0!3m2!1sen!2sin!4v1720602294384!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></CustomMap>                               
            </CustomContainer>
        </Box>
    </div>
    )
}

export default ContactUs
