import React from 'react'
import Header from './components/Header'
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import ContactUs from './components/ContactUs'
import Book from './components/Book'
import Footer from './components/Footer'

const App = () => {
  return (
    <div>
      <Header/>
      <Home/>
      <AboutUs/>
      <ContactUs/>
      <Book/>
      <Footer/>
    </div>
  )
}

export default App
