import { Box, styled } from '@mui/material'
import React from 'react'
import BGImage from '../pics/BackGround.jpg'
import GEDLogo from '../pics/GED-LOGO1.png'


const Home = () => {
    const BGimg=styled('img')(({theme})=>({
        paddingTop:'90px',
        opacity:'0.5',
        position:'absolute',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        width:'100%',
        [theme.breakpoints.down('md')]:{
            
        }
    }))
    const LogoImage=styled('img')(({theme})=>({
        marginTop:'50px',
        width:'80%',
        [theme.breakpoints.down('md')]:{
            marginTop:'70px',
            width:'100%'
        }
    }))

  return (
    <div id='home'>
        <Box sx={{overflow:'hidden',position:'relative'}}>
            <BGimg src={BGImage} alt=""/>
            <Box sx={{position: 'relative',textAlign:'center',backgroundColor:'rgba(90,90,90, 0.6)'}}>
                <LogoImage src={GEDLogo} alt="" />
            </Box>
        </Box>

    </div>
  )
}

export default Home
